import React from 'react';
import { Link } from 'gatsby';

const ITREligibilityResult = ({ eligible }) => {
    return (
      <div className='row itr-eli-result'>
        <div className='col-md-2'></div>
        <div className='col-md-8 card'>
          <div className='tax-input-block-border'>
            <h2 className='section-header'>
              ITR Eligibility Result<div className='bar'></div>
            </h2>
            <hr />
            <div className='itr-img'>
              <img src='/imgs/cta.png' className='itr-eli-img' alt='cta' />
            </div>
            {eligible ? (
              <div className='positive'>
                <p className='result'>
                  <strong>ITR Filling is Mandatory for you</strong>
                </p>
                <p className='text'>
                  As per Details entered, you need to file ITR
                </p>
              </div>
            ) : (
              <div className='negative'>
                <p className='result'>
                  <strong>ITR Filling is not Mandatory for you</strong>
                </p>
                <p className='text'>
                  However, we suggest you to file ITR to receive the following
                  benefits;
                </p>
                <div className='ul'>
                  <div className='row'>
                    <div className='col-md-1'>
                      <i className='bi bi-check-circle-fill'></i>
                    </div>
                    <div className='col-md-11'>
                      Quick approval for loans or credit cards
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-1'>
                      <i className='bi bi-check-circle-fill'></i>
                    </div>
                    <div className='col-md-11'>Faster processing of visas</div>
                  </div>
                  <div className='row'>
                    <div className='col-md-1'>
                      <i className='bi bi-check-circle-fill'></i>
                    </div>
                    <div className='col-md-11'>Request tax refunds</div>
                  </div>
                  <div className='row'>
                    <div className='col-md-1'>
                      <i className='bi bi-check-circle-fill'></i>
                    </div>
                    <div className='col-md-11'>
                      Use as income & address proof
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className='col-md-12 '>
              <Link to='/' className='btn btn-md form-next-btn btn-success m-2'>
                File ITR Now <i className='bi bi-arrow-right'></i>
              </Link>
              {/* <Link to='/mortgages/itrfiling' className='btn btn-md form-next-btn btn-success m-2'>
              File ITR Now <i className='bi bi-arrow-right'></i>
            </Link> */}
            </div>
          </div>
        </div>
        <div className='col-md-2'></div>
      </div>
    );
}

export default ITREligibilityResult;
