import React, { useState } from 'react';
import FAQAccord from '../GFlush';
import ContentImg from '../GSTTemp/ContentImg';
import ImgContent from '../GSTTemp/ImgContent';
import IncomeDetails from './IncomeDetails';
import ITREligibilityResult from './ITREligibilityResult';
import OtherDetails from './OtherDetails';
import PersonalDetails from './Personaldetails';

const ITRCheckerForm = () => {
  /* Img Content Component Data */
  const rentIcData = {
    id: 'itr-eligibility-checker',
    mt_div: 'mt-5',
    mt_img: '',
    header: 'What is an ITR?',
    image: '/imgs/registration/ipr/td4.png',
    alt_tag: 'Online ITR Eligibility Checker',
    points: [
      {
        head: '',
        content: `An assesses must submit a form known as an ITR, or income
                 tax return, to the Income Tax Department of India. `,
        icon: true,
      },
      {
        head: '',
        content: `This form contains all the details on the taxpayer's yearly 
                income as well as the total amount of tax due and refund due for
                 that specific year. `,
        icon: true,
      },
      {
        head: '',
        content: ` There are seven different ITR forms, and only those must 
                be used for submitting returns in accordance with the Central Board of 
                Direct Taxes (CBDT) of India.`,
        icon: true,
      },
      {
        head: 'Types of ITR Forms',
        content: `ITR-1,ITR-2,ITR-3,ITR-4,ITR-5,ITR-6,ITR-7,`,
        icon: true,
      },
    ],
  };
  /*  Content Img Component Data */
  const rentCiData = {
    id: 'steps',
    background: 'main-content',
    mt_div: '',
    mt_img: '',
    header: "How do I utilise TODAYFILINGS's eligibility checker?",
    paragraph: `Click on "Check Your Eligibility for ITR" on the "Income Tax Eligibility Checker" page`,
    points: [
      {
        head: ``,
        content: `Your date of birth and yearly income are requested on the 
                "Income Details" page. Click "Next" after accurately entering the information.`,
        icon: true,
      },
      {
        head: ``,
        content: `It then says "Other Details." Answer each question by selecting "yes" or "no" for each.`,
        icon: true,
      },
      {
        content: `The final screen appears, asking for personal information including complete 
                name, email address, and mobile number. Enter all the information, then press "Next." 
                (This step can also be skipped)`,
        icon: true,
      },
      {
        content: `The TODAYFILINGS eligibility checker indicates whether or not you must file an ITR. 
                If you must file an ITR, select the "File ITR Now" option provided below.`,
        icon: true,
      },
      {
        head: `Prerequisites for e-Filing`,
        content: `The important residence details documents that are required for filing income tax returns are Permanent account number
                Aadhar card,
                Bank Account details,`,
        icon: true,
      },
      {
        content: `And the forms to be submitted for filing income tax returns Form 16,
                Form 26AS,
                Investment details. `,
        icon: true,
      },
    ],
    image: '/imgs/business/checklist-nid.png',
    alt_tag: 'Steps For ITR Eligibility Checking',
  };
  /* FaQ data */
  var RentGenFAQ = [
    {
      header: 'What advantages can ITR filings offer?',
      body: [
        {
          content: ` Instances in which the total taxable income is below the 
                    exemption threshold. Particularly those who get dividends or interest 
                    on deposits, which are typically subject to tax withholding.`,
          icon: true,
        },
        {
          content: `Quick and painless Visa processing ITR documents that have 
                    been timely submitted for the previous two years must be presented at 
                    embassies or consulates.`,
          icon: true,
        },
        {
          content: `To stay out of trouble According to the Income Tax Act of 1961,
                     the tax office has the right to impose a fine of Rs. 5,000 on you if you
                      fail to file your ITRs on a regular basis.`,
          icon: true,
        },
        {
          content: `For carrying over losses to get high-coverage insurance policies.`,
          icon: true,
        },
      ],
    },
    // 2
    {
      header: 'Mandatory to file ITRs?',
      body: [
        {
          content: `If an individual's total income exceeds the basic exemption limit of 
                    Rs. 2,50,000, 300,000, or 500,000 in a financial year, they are required to file a tax return.`,
          icon: false,
        },
      ],
    },
    {
      header: `What are the formats for income tax returns that businesses and enterprises can use?`,
      body: [
        {
          content: `ITR-3, ITR-4, and ITR-5 are the forms used for businesses alone. 
                    ITR-6, however, is only utilized by businesses.`,
          icon: true,
        },
        {
          content: `Try to retain your rent receipts on hand, even if your HRA is 
                    less than Rs. 3000, in case the income tax officer needs them later.`,
          icon: true,
        },
      ],
    },
    // 3
    {
      header: `What are the repercussions of failing to file a tax return by a certain date?`,
      body: [
        {
          content: `You are need to file a belated return and a new ITR because the previous 
                    one is void if you don't file your tax returns by the deadline. Late filings are 
                    subject to a penalty of up to Rs. 5,000 under clause 234F.`,
          icon: true,
        },
        {
          content: `However, for an income of Rs. 5,00,000, the fine is only Rs. 1000. Additionally,
                     if the total gross income is Rs. 2,50,000 or less, no penalty would be assessed.`,
          icon: true,
        },
      ],
    },
  ];
  const [currentStep, setCurrentStep] = useState(1);
  const [eligible, setEligible] = useState('');
  const [validationError, setValidationError] = useState({
    dob: '',
    annual_income: '',
    name: '',
    email: '',
    phone: '',
  });
  //state for form data
  const [formData, setFormData] = useState({
    dob: '',
    annual_income: '',
    name: '',
    email: '',
    phone: '',
  });
  // console.log(formData);
  // const [receiptData, setReceiptData] = useState(false);
  const IsEligible = () => {
    setEligible(formData.annual_income >= 500000);
  };

  // function for going to next step by increasing step state by 1
  const nextStep = () => {
    if (!formData.dob) {
      setValidationError({
        ...validationError,
        dob: 'Please Select DOB',
      });
      return;
    }
    if (!formData.annual_income) {
      setValidationError({
        ...validationError,
        annual_income: 'Please Enter Annual Income',
      });
      return;
    }
    setCurrentStep(currentStep + 1);
  };
  // function for going to previous step by decreasing step state by 1
  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  // handling form input data by taking onchange value and updating our previous form data state
  const handleInputData = (input) => (e) => {
    // input value from the form
    const { value } = e.target;

    //updating for data state taking previous state and then adding new value to create new object
    setFormData((prevState) => ({
      ...prevState,
      [input]: value,
    }));
    if (value) {
      setValidationError({
        ...validationError,
        [input]: '',
      });
    }
  };

  const renderSwitch = (param) => {
    switch (param) {
      // case 1 to show stepOne form and passing nextStep, prevStep, and handleInputData as handleFormData method as prop and also formData as value to the fprm
      case 1:
        return (
          <IncomeDetails
            validationError={validationError}
            nextStep={nextStep}
            handleFormData={handleInputData}
            values={formData}
          />
        );
      // case 2 to show stepTwo form passing nextStep, prevStep, and handleInputData as handleFormData method as prop and also formData as value to the fprm
      case 2:
        return (
          <OtherDetails
            nextStep={nextStep}
            prevStep={prevStep}
            handleFormData={handleInputData}
            values={formData}
          />
        );
      // Only formData is passed as prop to show the final value at form submit
      case 3:
        return (
          <PersonalDetails
            prevStep={prevStep}
            handleFormData={handleInputData}
            values={formData}
            handleCheck={handleCheck}
          />
        );
      case 4:
        return <ITREligibilityResult eligible={eligible} />;
      // default case to show nothing
      default:
        return <div></div>;
    }
  };
  const handleCheck = (e) => {
    if (!formData.name) {
      setValidationError({
        ...validationError,
        name: 'Please Enter Your Name',
      });
      return;
    }
    if (!formData.email) {
      setValidationError({
        ...validationError,
        email: 'Please Enter Your Email',
      });
      return;
    }
    if (!formData.phone) {
      setValidationError({
        ...validationError,
        phone: 'Please Enter Mobile Number',
      });
      return;
    }
    // alert('asdasdsd')
    IsEligible();
    nextStep();
  };
  return (
    <>
      <section className='house-rent otherdetail'>
        <div className='container  mt-5'>
          <div className='row house-rent-receipt mt-5'>
            <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center p-0 mt-3 mb-2'>
              <div className=' px-0 pt-4 pb-0 mt-5 mb-3'>
                {/* <h2 id='heading' className='mt-5'>
                            Rent Receipt Generator
                        </h2>
                        <p>Fill all form field to go to next step</p> */}
                <form id='msform'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='rent-text'>
                        <h1 className='rent-head section-header'>
                          Income Tax Eligibility Calculator
                          <div className='bar'></div>
                        </h1>
                        <p className='rent-paragraph'>
                          My TDS has already been deducted so i don't have to
                          file ITR
                        </p>
                        <p className='rent-paragraph'>
                          My income is below taxable limit (Rs. 2.5 lacs*) so i
                          don't have to file ITR
                        </p>
                      </div>
                      <div className='itr-img-div'>
                        <img
                          src='/imgs/tax2.png'
                          className='itr-img'
                          alt='ITR Eligibility Checker'
                        />
                      </div>
                    </div>

                    <div className='col-md-6 mt-5'>
                      {renderSwitch(currentStep)}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ImgContent item={rentIcData} />

      <ContentImg CISection_data={rentCiData} />
      <section className='rent-faq'>
        <FAQAccord items={RentGenFAQ} />
      </section>
    </>
  );
};
export default ITRCheckerForm;
