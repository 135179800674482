import React from 'react';

const PersonalDetails = ({ handleCheck, handleFormData, values, prevStep }) => {
    return (
      <div className='row other-details'>
        <div className='col-md-2'></div>
        <div className='col-md-8 card'>
          <div className='row'>
            <div className='col-md-3'>
              <button
                className='btn btn-sm  form-prev-btn btn-primary'
                onClick={prevStep}
              >
                <i className='bi bi-arrow-left'></i>Back
              </button>
            </div>
            <div className='col-md-9'>
              <h2 className='section-header'>
                Personal Details<div className='bar'></div>
              </h2>
            </div>
          </div>
          <hr />
          <div className='tax-input-block-border'>
            <form>
              <div className='mb-3'>
                <label htmlFor='Full Name' className='form-label'>
                  Full Name<span className='text-danger required'>*</span>
                </label>
                <input
                  type='text'
                  className='rent-input form-control'
                  id='name'
                  name='name'
                  autoComplete='off'
                  required
                  placeholder='Enter Your Name'
                  value={values.name}
                  onChange={handleFormData('name')}
                />
              </div>
              <div className='mb-3'>
                <label htmlFor='Your Email' className='form-label'>
                  Email<span className='text-danger required'>*</span>
                </label>
                <input
                  type='email'
                  className='rent-input form-control'
                  id='email'
                  name='email'
                  autoComplete='off'
                  placeholder='Enter Your Email'
                  required
                  value={values.email}
                  onChange={handleFormData('email')}
                />
              </div>

              <div className='mb-3'>
                <label htmlFor='Phone' className='form-label'>
                  Mobile<span className='text-danger required'>*</span>
                </label>
                <input
                  type='phone'
                  className='rent-input form-control'
                  id='phone'
                  name='phone'
                  autoComplete='off'
                  placeholder='Enter Your phone'
                  required
                  value={values.phone}
                  onChange={handleFormData('phone')}
                />
              </div>
              <div className='col-md-12 '>
                <button
                  className='btn btn-md form-next-btn btn-success m-2'
                  onClick={handleCheck}
                >
                  Submit <i className='bi bi-arrow-right'></i>
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className='col-md-2'></div>
      </div>
    );
};

export default PersonalDetails;
