import React from 'react';

const IncomeDetails = ({
    nextStep,
    handleFormData,
    values,
    validationError,
}) => {
    return (
        <div className='row other-details'>
            <div className='col-md-2'></div>
            <div className='col-md-8 card'>
                <div className='tax-input-block-border'>
                    <h2 className='section-header'>
                        Income Details<div className='bar'></div>
                    </h2>
                    <hr />
                    <form>
                        <div className='mt-5 mb-3'>
                            <label htmlFor='Date Of Birth' className='form-label'>
                                Date Of Birth<span className='text-danger required'>*</span>
                            </label>
                            <input
                                type='date'
                                className='rent-input form-control'
                                id='dob'
                                name='dob'
                                required
                                autoComplete='off'
                                value={values.dob}
                                onChange={handleFormData('dob')}
                            />
                            {validationError.dob ? (
                                <p className='valid_error text-left text-danger'>
                                    {validationError.dob}
                                </p>
                            ) : (
                                ''
                            )}
                        </div>
                        <div className='mt-5 mb-3'>
                            <label htmlFor='Annual Income' className='form-label'>
                                Annual Income<span className='text-danger required'>*</span>
                            </label>
                            <input
                                type='text'
                                className='rent-input form-control'
                                id='annual_income'
                                name='annual_income'
                                required
                                autoComplete='off'
                                value={values.annual_income}
                                onChange={handleFormData('annual_income')}
                                placeholder='5,00,000'
                            />
                            {validationError.annual_income ? (
                                <p className='valid_error text-left text-danger'>
                                    {validationError.annual_income}
                                </p>
                            ) : (
                                ''
                            )}
                        </div>
                        <div className='col-md-12 '>
                            <button
                                className='btn btn-md form-next-btn btn-success m-2'
                                onClick={nextStep}
                            >
                                Next <i className='bi bi-arrow-right'></i>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <div className='col-md-2'></div>
        </div>
    );
};

export default IncomeDetails;
