import React from 'react';

const OtherDetails = ({ nextStep, prevStep }) => {

    return (
      <div className="row other-details">
        <div className="col-md-2"></div>
        <div className="col-md-8 card">
          <div className="row">
            <div className="col-md-3">
              <button
                className="btn btn-sm  form-prev-btn btn-primary"
                onClick={prevStep}
              >
                <i className="bi bi-arrow-left"></i>Back
              </button>
            </div>
            <div className="col-md-9">
              <h2 className="section-header">
                Other Details<div className="bar"></div>
              </h2>
            </div>
          </div>
          <hr />
          <div className="tax-input-block-border">
            <form>
              <div className="">
                <label htmlFor="Full Name" className="form-label">
                  <p className="other-details-text">
                    In any current bank account during FY 2022–23, have you
                    deposited more than one crore?
                  </p>
                </label>
                <tbody>
                  <tr>
                    <td>
                      <input type="radio" name="yes" />
                      <span> Yes</span>
                    </td>
                    <td>
                      <input type="radio" name="no" checked />
                      <span> No</span>
                    </td>
                  </tr>
                </tbody>
              </div>
              <hr />
              <div className="">
                <label htmlFor="Your Email" className="form-label">
                  <p className="other-details-text">
                    {" "}
                    Have you paid an electrical bill of more than one lakh over
                    the fiscal years 2022–23?
                  </p>
                </label>
                <tbody>
                  <tr>
                    <td>
                      <input type="radio" name="yes1" />
                      <span> Yes</span>
                    </td>
                    <td>
                      <input type="radio" name="no1" checked />
                      <span> No</span>
                    </td>
                  </tr>
                </tbody>
              </div>
              <hr />

              <div className="">
                <label htmlFor="Phone" className="form-label">
                  <p className="other-details-text">
                    {" "}
                    Have you or anyone else spent more than 2 lakhs on
                    international travel in the fiscal years 2022–23?
                  </p>
                </label>
                <tbody>
                  <tr>
                    <td>
                      <input type="radio" name="yes2" />
                      <span> Yes</span>
                    </td>
                    <td>
                      <input type="radio" name="no2" checked />
                      <span> No</span>
                    </td>
                  </tr>
                </tbody>
              </div>
              <hr />

              <div className="">
                <label htmlFor="Phone" className="form-label">
                  <p className="other-details-text">
                    Do you own any property, have any bank accounts, or have any
                    signing authority with any foreign-based entities?
                  </p>
                </label>
                <tbody>
                  <tr>
                    <td>
                      <input type="radio" name="yes3" />
                      <span> Yes</span>
                    </td>
                    <td>
                      <input type="radio" name="no3" checked />
                      <span> No</span>
                    </td>
                  </tr>
                </tbody>
              </div>
              <hr />

              <div className="col-md-12 ">
                <button
                  className="btn btn-md form-next-btn btn-success m-2"
                  onClick={nextStep}
                >
                  Next <i className="bi bi-arrow-right"></i>
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="col-md-2"></div>
      </div>
    );
}

export default OtherDetails;
