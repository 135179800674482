import React from 'react'
import ITRCheckerForm from '../../components/ITREligibilityChecker/ITRCheckerForm'
import Layout from '../../components/Layout'
import { Seo } from '../../components/seo'


export default function ITREligibilityChecker() {
    return (
      <>
        <Seo
          title='ITR Eligibility Checker'
          keywords='Keywords'
          description='Description'
          name='TODAYFILINGS'
          type='Website'
          author='TODAYFILINGS'
        />
        <Layout>
          <ITRCheckerForm />
        </Layout>
      </>
    );
}
